<template>
    <no-ssr>
      <div class="bg-cercles">
        <div class="bb-login">
          <div class="bb-container">
            <div class="bb-login__container">
              <div class="login-box">
                <h1 class="login-box__title">{{ $t("login.recover.resetPassword") }}</h1>
  
                <Form
                    class="form"
                    :operation="operation"
                    :valid="valid"
                    :error="error"
                >
                  <template>
                    <div class="login-box__inputs" v-if="!isConfirmed">
                      <div class="bb-form-item">
                        <label for="email" class="bb-form-label">{{ $t("login.password") }}:</label>
                        <FormInput
                            class="bb-form"
                            v-model="password"
                            name="password"
                            type="password"
                            :invalid="passwordEmpty"
                            :placeholder="$t('login.password')"
                        />
                      </div>
                      <div class="bb-form-item">
                        <label for="Contrase-a" class="bb-form-label"
                        >{{ $t("login.recover.confirmPassword") }}:</label
                        >
                        <FormInput
                            class="bb-form"
                            name="password"
                            type="password"
                            v-model="password2"
                            :invalid="password2Empty"
                            :placeholder="$t('login.recover.confirmPassword')"
                            :style="stylePwdComparation"
                        />
                      </div>
                      <p>
                        <span v-if="!validLength">&#10005; </span>
                        <span style="color: green;" v-else><strong>&#10003; </strong></span>
                        {{ $t("login.recover.minLength") }}</p>
                      <p>
                        <span v-if="!validComplexity">&#10005; </span>
                        <span v-else style="color: green;"><strong>&#10003; </strong></span>
                        {{ $t("login.recover.complexity") }}</p>
                    </div>
                    <div v-if="isConfirmed">
                        <p>{{ $t("login.recover.success") }}</p>
                        <!-- You can add additional content or instructions here -->
                    </div>
                  </template>
  
                  <template slot="actions">
                    <div class="login-box__actions">
                      <input
                        v-if="!isConfirmed"
                          type="submit"
                          :disabled="!valid"
                          :value="$t('login.recover.resetPassword')"
                          :data-wait="$t('login.recover.justASecond')"
                          class="button button--primary button--block button--lg"
                      />
                      <input v-else class="button button--primary button--block button--lg" :value="$t('login.recover.goToLogin')" :data-wait="$t('login.recover.justASecond')" @click="$router.push({ name: 'login' })">
                    </div>
  
                    <!-- <div class="login-box__recovery">
                      ¿Olvidaste tu contraseña?
                      <router-link to="/recoverpwd">
                        Solicítala de nuevo</router-link
                      >
                    </div> -->
                  </template>
                </Form>
              </div>
  
              <!-- <div class="login-account">
                <div class="login-account__picture">
                  <img src="../../assets/img/logos/favicon.svg" alt="" />
                </div>
                <div class="login-account__content">
                  <span>¿Todavía no tienes lista?</span>
                  <router-link to="/signup">Créala ahora</router-link>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </no-ssr>
  </template>
  
  <script>
  import NoSSR from "vue-no-ssr";
  export default {
    name: "resetPassword",
    components: {
      "no-ssr": NoSSR,
    },
    metaInfo() {
      return {
        title: this.$t("login.recover.resetPassword") + " | HelloBB",
        meta: [{name: "description", content: this.$t("login.recover.resetPassword") + "."}],
        link: [{ rel: "Restablece", href: "https://www.hellobb.net/restablecer-pwd" }],
      };
    },
    data() {
      return {
        password: "",
        password2: "",
        email: "",
        loading: false,
        emailEmpty: false,
        passwordEmpty: false,
        password2Empty: false,
        nameEmpty: false,
        error: null,
        isConfirmed: false,
      };
    },
    computed: {
      valid() {
        return (this.password == this.password2) && (this.password != "") && (this.password2 != "") && this.validLength && this.validComplexity; 
      },
      validLength() {
        return (this.password.length >= 8);
      },
      validComplexity() {
        return ((this.password.match(/[a-z]/g) || this.password.match(/[A-Z]/g)) && this.password.match(/[0-9]/g));
      },
      stylePwdComparation() {
        //if password not have 4 chars don't show the red border
        if(this.password.length < 4) {
          return "";
        }
        if(this.password != this.password2) {
          return "border-color: red;";
        } else {
          return "";
        }
      }
    },
    methods: {
      async operation() {
        if (this.validations()) {
          let resetToken = this.$route.query.token;
          let response = await this.$store.dispatch("resetPassword", {
            newPassword: this.password,
            resetToken: resetToken
          });
          if(response.status == 200) {
            this.isConfirmed = true;
          } else {
            //this.error = response.data.message;
            this.error = "Ha ocurrido un error. Por favor, inténtalo de nuevo."
          }
        }
      },
      validations() {
        return (this.password == this.password2) && (this.password != "") && (this.password2 != ""); 
        this.valid = (this.password == this.password2) && (this.password != "") && (this.password2 != ""); 
        return valid;
      },
    },
  };
  </script>
  